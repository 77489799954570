export const useCdn = (imgPath) => {
  const cdnUrl = 'https://cdn.elker.com'
  const oldImgPath = 'https://elker.b-cdn.net'
  if (!imgPath) return ''
  if (!imgPath.includes(oldImgPath)) return imgPath
  if (imgPath && cdnUrl) {
    const newImgPath = imgPath.split('elker.b-cdn.net')[1]
    return cdnUrl + newImgPath
  }
  return imgPath
}